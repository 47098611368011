<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Manage realtors" />
        <div class="loader">
          <Wave
            v-if="loading || updating || deleting"
            width="50px"
            color="#d9534f"
          />
        </div>
      </div>
      <md-table
        v-model="realtors.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4">
          <div class="md-toolbar-section-start">
            <md-button
              class="md-primary button-icon"
              @click="exportExcel"
              :disabled="exporting"
            >
              Download Excel
              <img src="@/assets/svg/excel.svg" />
            </md-button>
          </div>
          <div class="toolbar-filter-wrapper">
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by name..." v-model="search" />
            </md-field>
            <md-menu md-size="medium" md-align-trigger>
              <md-button
                md-menu-trigger
                class="md-primary button-icon"
                disabled
              >
                Filter
                <img src="@/assets/svg/filter.svg" />
              </md-button>

              <md-menu-content>
                <md-menu-item>Approved</md-menu-item>
                <md-menu-item>Pending</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-toolbar>
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          :class="{ 'table-highlight': item.promote_count > 0 }"
        >
          <md-table-cell md-label="S/N" md-numeric>{{ item.id }}</md-table-cell>
          <!-- <md-table-cell md-label="Name">{{ item.user.name }}</md-table-cell> -->
          <!-- <md-table-cell md-label="Email">{{ item.user.email }}</md-table-cell> -->
          <md-table-cell md-label="Phone number">{{
            item.realtor_phone || (item.user && item.user.phone)
          }}</md-table-cell>
          <md-table-cell md-label="Shop name">{{
            item.shop_name
          }}</md-table-cell>
          <md-table-cell md-label="Company">{{
            item.company_name
          }}</md-table-cell>
          <md-table-cell md-label="Registered By">
            <div class="badge yellow">
              {{
                item.register
                  ? item.register.user.first_name +
                    " " +
                    item.register.user.last_name
                  : "User"
              }}
            </div>
          </md-table-cell>
          <md-table-cell md-label="Tracking code">{{
            item.tracking_code
          }}</md-table-cell>
          <md-table-cell md-label="Status">
            <div
              class="badge"
              :class="{
                success: item.approval_status == 'approved',
                danger: item.approval_status == 'disapproved',
              }"
            >
              {{ item.approval_status }}
            </div></md-table-cell
          >
          <md-table-cell md-label="Actions">
            <md-menu md-size="medium" md-align-trigger>
              <md-button :disabled="updating" md-menu-trigger class="bggreen"
                >Menu</md-button
              >

              <md-menu-content>
                <!-- <md-menu-item
                  v-if="item.promote_count == 0"
                  @click="promote(item)"
                  >Promote <i class="material-icons">arrow_upward</i>
                </md-menu-item>
                <md-menu-item v-else @click="promote(item, 'update')"
                  >Update Promotion
                </md-menu-item> -->
                <md-menu-item @click="setRealtor(item)">Preview</md-menu-item>
                <md-menu-item
                  v-if="
                    ['disapproved', 'pending'].includes(item.approval_status)
                  "
                  @click="changeStatus(item.id, 'approved')"
                  >Approve</md-menu-item
                >
                <md-menu-item
                  v-if="['approved', 'pending'].includes(item.approval_status)"
                  @click="changeStatus(item.id, 'disapproved')"
                  >Disapprove</md-menu-item
                >
                <md-menu-item>Delete </md-menu-item>
              </md-menu-content>
            </md-menu>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="realtors.mdPage"
        :records="realtors.mdCount"
        :per-page="realtors.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>

    <md-dialog :md-active.sync="show">
      <md-dialog-title>Realtor Details</md-dialog-title>
      <div class="cancel-modal">
        <i class="material-icons" @click="show = false">close</i>
      </div>

      <div class="modal-contain" v-if="realtor">
        <div v-if="realtor.user">
          <div class="item">
            <strong>Company name</strong>
            <span> {{ realtor.company_name }}</span>
          </div>
          <div class="item">
            <strong>Shop name</strong>
            <span> {{ realtor.shop_name }}</span>
          </div>
          <div class="item">
            <strong>About</strong>
            <span v-html="realtor.about"> </span>
          </div>
          <div class="item">
            <strong>Rating</strong>
            <span> {{ realtor.rating }}</span>
          </div>

          <div class="item">
            <strong>Phone Number</strong>
            <a :href="`tel:${realtor.phone_ext + realtor.phone}`">
              {{ realtor.realtor_phone_ext + realtor.realtor_phone }}</a
            >
          </div>

          <div class="item">
            <strong>Date Created</strong>
            <span> {{ realtor.created_at | formatDate }}</span>
          </div>

          <div class="item">
            <strong>Approval Status</strong>
            <div
              class="badge"
              :class="{
                success: realtor.approval_status == 'approved',
                danger: realtor.approval_status == 'disapproved',
              }"
            >
              {{ realtor.approval_status }}
            </div>
          </div>

          <div class="item">
            <strong>Tracking code</strong>
            <span> {{ realtor.tracking_code }}</span>
          </div>

          <div class="item">
            <strong>Tin</strong>
            <span> {{ realtor.tin }}</span>
          </div>

          <div class="media">
            <div class="doc-display">
              <img
                :src="realtor.cac_reg_upload"
                onerror="this.src='https://via.placeholder.com/220x150?text=No%20Image'"
                alt="Display Image"
              />
            </div>
          </div>

          <hr />
          <div>
            <h4>Socials information</h4>
          </div>

          <div class="item">
            <strong>Facebook</strong>
            <span> {{ realtor.facebook_link }}</span>
          </div>

          <div class="item">
            <strong>Linkedin</strong>
            <span> {{ realtor.linkedin_link }}</span>
          </div>
          <div class="item">
            <strong>Instagram</strong>
            <span> {{ realtor.instagram_link }}</span>
          </div>

          <hr />
          <div>
            <h4>User information</h4>
          </div>
          <div class="item">
            <strong>Fullname</strong>
            <span> {{ realtor.user.name }}</span>
          </div>

          <div class="item">
            <strong>Email Address</strong>
            <span> {{ realtor.user.email }}</span>
          </div>

          <div class="item">
            <strong>Phone Number</strong>
            <a :href="`tel:${realtor.user.phone_ext + realtor.user.phone}`">
              {{ realtor.user.phone_ext + realtor.user.phone }}</a
            >
          </div>
        </div>
        <div v-else>
          <div class="alert alert-info">User Object not found</div>
        </div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("realtor");
export default {
  name: "realtor",
  data() {
    return {
      updating: false,
      deleting: false,
      search: "",
      show: false,
      time: null,
      realtor: null,
      exporting: false,
    };
  },
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },

  methods: {
    ...mapActions(["getRealtors", "updateRealtorStatus"]),
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getRealtors(data);
    },
    async changeStatus(id, status) {
      try {
        this.updating = true;
        await this.updateRealtorStatus({ approval_status: status, id });
        this.updating = false;
        this.toast("success", "realtor Status Updated ");
      } catch (err) {
        this.updating = false;
        console.error(err);
      }
    },
    async submitSearch() {
      await this.getRealtors({ keyword: this.search });
    },
    async promote(item, mode = "create") {
      // this.$eventBus.$emit("promote", {
      //   item_type: this.promotions.realtor,
      //   item_id: item.id,
      //   title: item.company_name,
      //   promote: item.promote,
      //   mode,
      // });
    },
    async exportExcel() {
      this.exporting = true;
      try {
        await this.$store.dispatch("auth/export", { model: "realtor" });
      } catch (err) {
        this.toast("error", "Unable to Export");
      } finally {
        this.exporting = false;
      }
    },
    setRealtor(realtor) {
      this.show = true;
      this.realtor = realtor;
    },
    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
  },
  created() {},
  mounted() {
    this.getRealtors();
  },
  computed: {
    ...mapGetters(["realtors", "loading"]),
  },
};
</script>

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}

.badge {
  background: #f3f3f3;

  &.success {
    background: green;
  }

  &.danger {
    background: red;
  }
}
</style>
